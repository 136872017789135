/* styles.css */
.card__body{
    background: rgba(255, 255, 255, 0.17) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(3.4px) !important;
    -webkit-backdrop-filter: blur(3.4px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
  }
  
  .card--header{
    padding: 10px;
    margin-bottom: 5px;
    font-size: 20px;
  }
  
  .card--body{
    font-size: 18px;
    color: black;
  }
  
  .card--footer{
    color: gray;
    text-align: center;
  }
  .card__title{
    background: rgba(255, 255, 255, 0.28) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
	margin-top: 10px !important;
	margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
  .fab__button {
    position: relative;
    margin: 5;
  }
  .text__title {
    font-size: 35px;
    font-family: 'Courier New', Courier, monospace;
    color: white;
  }

  .button_primary {
    color: #007BFF
  }
  .button_secondary{
    color: #6C757D
  }
  .button_success{
    color: #28A745
  }
  .button_danger{
    color: #DC3545
  }
  .button_warning{
    color: #FFC107
  }
  .button_info{
    color: #17A2B8
  }
  .button_dark{
    color: #343A40
  }
  .button_fill_primary{
    background-color: #007BFF;
    color:white
    
  }
  .button_fill_secondary{
    background-color: #6C757D;
    color:white

  }
  .button_fill_success{
    background-color: #28A745;
    color:white

  }
  .button_fill_danger{
    background-color: #DC3545;
    color:white

  }
  .button_fill_warning{
    background-color: #FFC107;
    color:white

  }
header {
  font-family: 'Lobster', cursive;
  text-align: center;
  font-size: 25px;
}

#info {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-bottom: 25px;
}

a {
  color: #074E8C;
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  /*
  width: 65px; */
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.force-overflow {
  min-height: 450px;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D62929;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}